import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { Mixpanel } from 'shared/utils/mixpanel';
import { env } from 'env';
import { appInfo } from 'pages/Application';
import { useCareAuthContext } from 'shared/providers/CareAuthProvider';
import usePrevious from './usePrevious';

const useMixpanel = (): void => {
  const { isAuthenticated, clinic: currentClinic, clinicPetParent: user } = useCareAuthContext();
  const hasInitialized = useRef(false);

  useEffect(() => {
    try {
      if (!(env.REACT_APP_PRODUCTION === 'true')) return;
      Mixpanel.init(env.REACT_APP_MIXPANEL_TOKEN);
    } catch (error) {
      console.error(error);
    }

    return (): void => {
      try {
        Mixpanel.reset();
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  const isAuthenticatedPrevious = usePrevious(isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated && !!isAuthenticatedPrevious) {
      Mixpanel.reset();
    }
  }, [isAuthenticated, isAuthenticatedPrevious]);

  useEffect(() => {
    if (user && env.REACT_APP_PRODUCTION === 'true' && !hasInitialized.current) {
      hasInitialized.current = true;
      try {
        /**
         * Mixpanel.register() adds super properties, i.e. properties that are sent with every event.
         * For details: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#super-properties
         */
        Mixpanel.register({
          ...appInfo,
          clinicId: currentClinic?.id || '',
          clinicName: currentClinic?.name || '',
        });
        Mixpanel.identify(user.id);
        Mixpanel.people.set({
          userId: user.id,
          application: 'Care Web',
          appName: 'Care Web',
          clinicName: currentClinic?.name || 'Unknown clinic',
        });
      } catch (e) {
        Sentry.captureException(e);
        console.error('Mixpanel initialization failed: ', e.message);
      }
    }
  }, [user, currentClinic]);
};

export default useMixpanel;
