import mixpanel, { Dict } from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import { env } from 'env';

const actions = {
  init: (token?: string): void => {
    try {
      if (token) mixpanel.init(token);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
  register: (properties?: { [key: string]: string | number }): void => {
    try {
      if (properties) mixpanel.register(properties);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
  identify: (id?: string): void => {
    try {
      mixpanel.identify(id);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
  alias: (id: string): void => {
    try {
      mixpanel.alias(id);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
  track: (name: string, props?: Dict): void => {
    try {
      if (env.REACT_APP_PRODUCTION === 'true') {
        mixpanel.track(name, props);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
  people: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set: (props?: Record<string, unknown>): void => {
      if (!props) return;
      try {
        mixpanel.people.set(props);
      } catch (e) {
        Sentry.captureException(e);
        console.error(e.message);
      }
    },
  },
  reset: (): void => {
    try {
      if (mixpanel) mixpanel.reset();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }
  },
};

export const Mixpanel = actions;
