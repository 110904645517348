import React, { useContext, useState, useMemo, ReactNode, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { ICareAuthContext } from './interfaces/ICareAuthContext';
import CareAuthContext from './context/CareAuthContext';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import {
  useCheckClinicCareStatusQuery,
  useFindPetParentByTokenQuery,
  useGetClinicPetParentEmailQuery,
  useGetClinicPetParentQuery,
} from 'shared/types/graphql';
import useActiveCampaign from 'shared/hooks/useActiveCampaign';
import { useTokenContext } from '../TokenProvider';
import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import { SessionStorageKeys } from 'shared/enums/SessionStorageKeys';
import useAppCommunication from 'shared/hooks/useAppCommunication';
import { BasicClinicPetParent } from 'shared/types/BasicClinicPetParent';
import * as Sentry from '@sentry/react';
import useClinicListResync from 'shared/hooks/useClinicListResync';
import { useCheckPlanEnrollment } from 'pages/Conversations/hooks/useCheckPlanEnrollment';

export const useCareAuthContext = (): ICareAuthContext => useContext<ICareAuthContext>(CareAuthContext);

interface ICareAuthContextProps {
  onIdentifyPetParent: (petParent: BasicClinicPetParent) => void;
  children: ReactNode;
}

const CareAuthProvider = ({ children, onIdentifyPetParent }: ICareAuthContextProps): JSX.Element | null => {
  const { token, redeemCode, logout, getLiveToken } = useTokenContext();
  const location = window.location.search;
  const isImpersonating = sessionStorage.getItem(SessionStorageKeys.IsImpersonating) === 'true';

  const params = useMemo(() => new URLSearchParams(location), [location]);
  const client = useApolloClient();

  const clinicId = useMemo(() => {
    if (params.get('clinicId')) {
      return params.get('clinicId');
    }
  }, [params]);

  useMemo(() => {
    if (params.has('impersonate') && params.get('impersonate') !== 'false') {
      sessionStorage.setItem(SessionStorageKeys.IsImpersonating, 'true');
    }

    if (
      params.has('expired_pet_parent_token') &&
      params.get('expired_pet_parent_token') !== 'false' &&
      getLiveToken()
    ) {
      logout({ clinicId: clinicId || '' });
    }
  }, [params, logout, clinicId, getLiveToken]);

  const clinicPetParentId = useMemo(() => {
    if (!token) return '';
    const payload = jwt.decode(token) as Record<string, string>;
    return payload?.clinicPetParentId || '';
  }, [token]);

  useEffect(() => {
    return localStorage.setItem(PersistedStateKeys.CachedToken, token || '');
  }, [token]);

  const { data: clinicCareStatusData } = useCheckClinicCareStatusQuery({
    variables: {
      data: {
        clinicId,
        token,
      },
    },
    skip: !(clinicId || token),
    context: {
      headers: {
        'skip-context': 'true',
      },
    },
    onError: (error) => {
      if (error.message === 'Not Authorized') {
        logout({ clinicId: clinicId || '' });
        return;
      }
    },
  });

  const code = params.get('code');
  useEffect(() => {
    if (code && code !== localStorage.getItem(PersistedStateKeys.CachedLastUsedCode)) {
      redeemCode(code, client as ApolloClient<InMemoryCache>);
      localStorage.setItem(PersistedStateKeys.CachedLastUsedCode, code);
    }
  }, [client, redeemCode, code, logout, clinicId]);

  const { identifyContact } = useActiveCampaign();
  const { data: clinicPetParentEmailData } = useGetClinicPetParentEmailQuery({
    skip: !token,
    onCompleted: (data) => {
      const email = data.findClinicPetParentByToken?.email;
      if (email) {
        identifyContact(email);
      }
    },
    onError: (error) => {
      if (error.message === 'Not Authorized') {
        logout({ clinicId: clinicId || '' });
        return;
      }
    },
  });

  const {
    data: petParentData,
    loading: isPetParentDataLoading,
    refetch: refetchPetParentData,
  } = useFindPetParentByTokenQuery({
    skip: !token,
    variables: {
      petTake: 20,
    },
  });
  const { isEnrolledInAtLeastOnePlan, unenrolledPets, hasUnenrolledPets } = useCheckPlanEnrollment({
    petParentData,
    isPetParentDataLoading,
  });

  const clinicPetParentEmail = clinicPetParentEmailData?.findClinicPetParentByToken?.email;

  const { data: clinicPetParentData, loading: loadingClinicPetParentData } = useGetClinicPetParentQuery({
    variables: {
      where: {
        id: clinicPetParentId,
      },
    },
    skip: !clinicPetParentId,
    onError: (error) => {
      Sentry.captureException(error);
      logout({ clinicId: clinicId || '' });
      return;
    },
    onCompleted: (data) => {
      if (!data.findUniqueClinicPetParent?.isActive) {
        logout({ clinicId: clinicId || '' });
      }
    },
  });

  const clinicPetParent = clinicPetParentData?.findUniqueClinicPetParent;
  const { isLoading, setClinicPetParentId } = useClinicListResync();

  useEffect(() => {
    if (!loadingClinicPetParentData) {
      // Means that the current pet parent is not active and we need to log him out from the app
      if (
        clinicPetParent?.id &&
        (clinicPetParent?.isDeleted || !clinicPetParent?.isActive || !clinicPetParent.clinic?.isClinicActive)
      ) {
        return logout({ clinicId: '' });
      }

      if (clinicPetParent) {
        onIdentifyPetParent(clinicPetParent);
      }
      setClinicPetParentId(clinicPetParent?.id || '');
    }
  }, [setClinicPetParentId, loadingClinicPetParentData, clinicPetParent, logout, onIdentifyPetParent]);

  const clinic = useMemo(() => {
    const tokenClinic = clinicCareStatusData?.checkClinicCareStatus?.tokenClinic;
    const idClinic = clinicCareStatusData?.checkClinicCareStatus?.idClinic;
    return idClinic || tokenClinic || undefined;
  }, [clinicCareStatusData]);

  const isCareEnabled = useMemo(() => !!clinic?.careEnabled, [clinic]);

  const featureFlags = useMemo(() => {
    const featureFlags = clinic?.enabledFeatureFlags;
    if (featureFlags) {
      return featureFlags;
    } else {
      return [];
    }
  }, [clinic?.enabledFeatureFlags]);

  const [careAuthState, setCareAuthState] = useState<ICareAuthContext>({
    token: token || '',
    isAuthenticated: !!token,
    isImpersonating,
    clinic,
    clinicPetParentId,
    clinicPetParentEmail,
    featureFlags,
    isCareEnabled: false,
    clinicPetParent,
    petParentData,
    isLoading,
    isPetParentDataLoading,
    refetchPetParentData,
    isEnrolledInAtLeastOnePlan,
    unenrolledPets,
    hasUnenrolledPets,
  });

  useEffect(() => {
    const newData = {
      token,
      isAuthenticated: !!token,
      isImpersonating,
      clinic,
      clinicPetParentId,
      clinicPetParentEmail,
      featureFlags,
      isCareEnabled,
      clinicPetParent,
      petParentData,
      isLoading,
      isPetParentDataLoading,
      refetchPetParentData,
      isEnrolledInAtLeastOnePlan,
      unenrolledPets,
      hasUnenrolledPets,
    };

    setCareAuthState(newData);
  }, [
    token,
    isImpersonating,
    clinic,
    clinicPetParentId,
    clinicPetParentEmail,
    isCareEnabled,
    featureFlags,
    clinicPetParent,
    petParentData,
    isLoading,
    isPetParentDataLoading,
    refetchPetParentData,
    isEnrolledInAtLeastOnePlan,
    unenrolledPets,
    hasUnenrolledPets,
  ]);

  useAppCommunication(clinicPetParentId);

  return <CareAuthContext.Provider value={careAuthState}>{children}</CareAuthContext.Provider>;
};

export default CareAuthProvider;
