import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import DogCatIcon from 'assets/icons/dog-cat.svg';
import BlueBubbleIcon from 'assets/icons/blue-bubble.svg';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Logo } from '@televet/kibble-ui/build/components/Logo';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { LocalStorageKeys } from 'shared/enums/LocalStorageKeys';

const TermsModal = (): JSX.Element => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  useEffect(() => {
    if (localStorage) {
      setIsTermsAccepted(!!localStorage.getItem(LocalStorageKeys.isTermsAccepted));
    }
  }, []);

  const handleStartChat = (): void => {
    if (localStorage) {
      localStorage.setItem(LocalStorageKeys.isTermsAccepted, 'true');
    }
    setIsTermsAccepted(true);
  };

  if (!isTermsAccepted) {
    return (
      <ModalWrapper>
        <ModalContent>
          <Top>
            <TopLeft>
              <Welcome>Hello</Welcome>
              <Looking>Thank you for trusting us with the care of your pet.</Looking>
            </TopLeft>
            <TopRight>
              <Circle>
                <StyledImage src={DogCatIcon} alt="" loading="lazy" />
              </Circle>
            </TopRight>
          </Top>
          <Center>
            <Continuing>By continuing you can:</Continuing>
            <ListItem>
              <Icon name="paw" size="sm" />
              <Text size="sm" ml={2} fontWeight="semibold">
                Confirm your appointment
              </Text>
            </ListItem>
            <ListItem>
              <Icon name="paw" size="sm" />
              <Text size="sm" ml={2} fontWeight="semibold">
                Send and receive pictures, videos, and files
              </Text>
            </ListItem>
            <ListItem>
              <Icon name="paw" size="sm" />
              <Text size="sm" ml={2} fontWeight="semibold">
                Ask any questions you have
              </Text>
            </ListItem>
            <Separator />
          </Center>
          <Bottom>
            <PreStatement>
              By clicking &quot;Continue&quot; you agree to the
              <StyledAnchor href="https://pet-terms-of-service.s3.us-east-2.amazonaws.com/terms.html" target="_blank">
                Otto Terms of Service
              </StyledAnchor>
              which includes the
              <StyledAnchor href="https://www.otto.vet/privacy-policy" target="_blank">
                Otto Privacy Policy.
              </StyledAnchor>
            </PreStatement>

            <Button
              mt={7}
              size="sm"
              isFullWidth={true}
              data-testid="curbside-terms-modal-continue-button"
              onClick={handleStartChat}
            >
              Continue
            </Button>

            <PoweredBy>
              <Logo type="icon" width={11} />
              <Text ml="5px">Powered by Otto</Text>
            </PoweredBy>
          </Bottom>
        </ModalContent>
      </ModalWrapper>
    );
  }
  return <></>;
};

export default TermsModal;

// TODO: convert to kibble
const Bottom = styled.div`
  flex: 1;
  padding: 0px 24px 24px 24px;
`;

const Center = styled.div`
  flex: 1;
  padding: 24px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  box-shadow: 0 13px 12px 0 rgba(57, 68, 70, 0.05);
  background-color: var(--chakra-colors-background-default);
`;

const Continuing = styled.p`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-default);
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-default);
`;

const Looking = styled.p`
  height: 48px;
  margin-top: 7px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-default);
  margin-bottom: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 357px;
  border-radius: 28px;
  box-shadow: 1px 2px 21px 0 rgba(36, 46, 42, 0.09);
  background-color: var(--chakra-colors-background-default);
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 31, 37, 0.2);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
`;

const PoweredBy = styled.div`
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: var(--chakra-colors-text-interactive);
`;

const PreStatement = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-default);
  user-select: none;
`;

const Separator = styled.div`
  width: 293px;
  height: 1px;
  border: solid 1px var(--chakra-colors-border-default);
  margin: auto;
  margin-top: 27px;
`;

const StyledAnchor = styled.a`
  margin: 0 6px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-interactive);
`;

const StyledImage = styled.img`
  width: 48px;
  height: 48px;
`;

const Top = styled.div`
  display: flex;
  height: 136px;
  background-image: url(${BlueBubbleIcon});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 24px 16px 0 24px;
  flex: 1;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopRight = styled.div``;

const Welcome = styled.p`
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chakra-colors-text-default);
`;
